.error-box {
  font-size: large;
  background-color: #ce0d0d;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 3px 3px #888888;
}
.error-box h2 {
  margin-top: 0;
}
.error-box button {
  height: 30px;
}
.flex-container {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
}
:local(.updateAvailableInfoRoot) {
  padding: 5px;
  background-color: darkslateblue;
  color: #fff;
  display: flex;
  border-radius: 5px;
}
:local(.updateAvailableInfoRoot) > div {
  margin-right: 5px;
  flex: 1;
}
:local(.updateAvailableInfoRoot) > div > span {
  font-weight: bold;
}
