.error-box {
  font-size: large;
  background-color: #ce0d0d;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 3px 3px #888888;
}
.error-box h2 {
  margin-top: 0;
}
.error-box button {
  height: 30px;
}
.flex-container {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
}
:local(.informationPanelRoot) {
  background-color: #f9ed68 !important;
  padding: 7px;
  overflow: scroll;
  margin-top: 20px;
  text-align: left;
  flex-direction: column;
  flex: 1;
}
:local(.informationPanelRoot) > p {
  font-size: large;
  margin: 0 !important;
}
