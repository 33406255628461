.error-box {
  font-size: large;
  background-color: #ce0d0d;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 3px 3px #888888;
}
.error-box h2 {
  margin-top: 0;
}
.error-box button {
  height: 30px;
}
.flex-container {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
}
:local(.editQuantityRoot) {
  display: flex;
  flex-direction: row;
  height: 100%;
}
:local(.editQuantityColumn) {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
}
:local(.editQuantityColumn) button .material-icons {
  font-size: 56px;
}
:local(.editQuantityColumn) > div,
:local(.editQuantityColumn) > button {
  margin: 7px;
  flex: 1 1 50%;
}
