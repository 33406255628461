.error-box {
  font-size: large;
  background-color: #ce0d0d;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 3px 3px #888888;
}
.error-box h2 {
  margin-top: 0;
}
.error-box button {
  height: 30px;
}
.flex-container {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
}
:local(.editQtyValue) {
  display: flex;
  flex-direction: column;
  min-width: 80px;
  text-align: right;
  margin-right: 10px;
  margin-bottom: 7px;
}
:local(.editQtyCurrentQty) {
  font-size: 66px;
  margin-bottom: -0.2em;
}
