@import './quino/styles/Styles';

body {
    font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
    color: #54626e;
    background-color: #f6f7f8;
    overscroll-behavior: contain;
}

/*
 * Workarounds for the following issues:
 * https://github.com/mui-org/material-ui-pickers/issues/1196
 * https://github.com/mui-org/material-ui-pickers/issues/1197
 */
.MuiPickersModal-dialogRoot {
    max-height: 100% !important;

    .MuiDialogActions-root {
        padding: 4px;
    }

    .MuiPickersTimePickerToolbar-hourMinuteLabelLandscape {
        margin-top: initial;
    }
}
