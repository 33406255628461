.error-box {
  font-size: large;
  background-color: #ce0d0d;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 3px 3px #888888;
}
.error-box h2 {
  margin-top: 0;
}
.error-box button {
  height: 30px;
}
.flex-container {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
}
:local(.contractDetailInformationRoot) {
  padding: 1px 3px;
  font-weight: 300;
  background-color: #f9ed68;
}
:local(.contractDetailInformationRoot) > p {
  margin: 0 !important;
}
