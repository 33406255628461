@startmenu-root: ~':local(.startmenuRoot)';

@{startmenu-root} {
    flex: 1;
    display: flex;
    flex-direction: column;

    > button {
        margin: 10px 0;
        flex: 1;
    }
}
