.error-box {
  font-size: large;
  background-color: #ce0d0d;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 3px 3px #888888;
}
.error-box h2 {
  margin-top: 0;
}
.error-box button {
  height: 30px;
}
.flex-container {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
}
:local(.aplixImagePickerRoot) div[aspectratio="cover"] div:first-child div:first-child {
  position: absolute;
  top: 90px;
  width: 365px;
  background: #ce0d0d;
  color: #333333;
  padding: 5px;
  border-radius: 4px;
  margin: 15px;
}
:local(.aplixImagePickerRoot) div:first-child {
  z-index: 1;
}
:local(.aplixImagePickerRoot) .MuiButton-outlined {
  margin-right: 7px;
  margin-bottom: 7px;
}
:local(.aplixImagePickerRoot) .image-container {
  display: flex;
}
:local(.aplixImagePickerRoot) .image-container .image {
  display: flex;
}
:local(.aplixImagePickerRoot) .image-container .image img {
  width: 100%;
  max-height: 250px;
  border-radius: 4px;
}
:local(.aplixImagePickerRoot) .image-container .image .broken-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 387px;
  min-height: 218px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: lightgray;
}
:local(.aplixImagePickerRoot) .image-container .image-buttons {
  margin-left: 7px;
}
:local(.cameraControlPanel) {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  width: 20%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
  align-items: center;
  justify-content: space-evenly;
  padding: 50px;
  box-sizing: border-box;
  flex-direction: column;
}
@media (max-aspect-ratio: 1) {
  :local(.cameraControlPanel) {
    flex-direction: row;
    bottom: 0;
    width: 100%;
    height: 20%;
  }
}
@media (max-width: 400px) {
  :local(.cameraControlPanel) {
    padding: 10px;
  }
}
