.error-box {
    font-size: large;
    background-color: @color-error-bg;
    color: @color-error-fg;
    padding: @size-padding-textbox * 2;
    border-radius: @size-panel-borderradius;
    box-shadow: 0 3px 3px #888888;

    h2 {
        margin-top: 0;
    }

    button {
        height: 30px;
    }
}
