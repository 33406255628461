.error-box {
  font-size: large;
  background-color: #ce0d0d;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 3px 3px #888888;
}
.error-box h2 {
  margin-top: 0;
}
.error-box button {
  height: 30px;
}
.flex-container {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
}
:local(.aplixErrorDialogRoot) div > div:last-child {
  margin: 0 !important;
  padding: 10px;
}
:local(.aplixErrorDialogRoot) div > div,
:local(.aplixErrorDialogRoot) h2 {
  background-color: #ce0d0d !important;
  color: #fff !important;
}
:local(.aplixErrorDialogRoot) button {
  color: #fff;
  border: 1px solid #fff;
}
