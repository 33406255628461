.error-box {
  font-size: large;
  background-color: #ce0d0d;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 3px 3px #888888;
}
.error-box h2 {
  margin-top: 0;
}
.error-box button {
  height: 30px;
}
.flex-container {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
}
:local(.contractInformationRoot) {
  display: flex;
  flex: 1;
  height: 100%;
  padding-top: 10px;
}
:local(.contractInformationRoot) > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}
:local(.contractInformationRoot) > div div:not(:first-child) {
  margin-top: 7px;
}
:local(.contractInformationRoot) #information {
  max-height: 155px;
}
:local(.contractInformationCustomer) {
  flex: 1;
}
:local(.contractInformationButtonBar) {
  padding-top: 20px;
  text-align: right;
  flex: unset !important;
}
:local(.contractInformationButtonBar) button span.material-icons {
  margin-left: 7px;
}
