.error-box {
  font-size: large;
  background-color: #ce0d0d;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 3px 3px #888888;
}
.error-box h2 {
  margin-top: 0;
}
.error-box button {
  height: 30px;
}
.flex-container {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
}
:local(.startContractPageForm) {
  display: flex;
  align-items: center;
}
:local(.starContractPageManualButton) {
  padding: 20px 30px !important;
}
:local(.startContractPageContractNumber) {
  flex: 1;
  margin-right: 7px;
}
:local(.startContractPageSendIcon) {
  margin-left: 7px;
}
