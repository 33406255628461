@CONTRACT_TABLE_ROOT: ~':local(.contractTableRoot)';

@{CONTRACT_TABLE_ROOT} {
    height: 100%;
    overflow-y: scroll;
    
    .MuiTableCell-root {
        padding-right: 24px !important;
    }
}
