.error-box {
  font-size: large;
  background-color: #ce0d0d;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 3px 3px #888888;
}
.error-box h2 {
  margin-top: 0;
}
.error-box button {
  height: 30px;
}
.flex-container {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
}
:local(.productInfoRoot) {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  min-height: 100%;
}
:local(.productInfoTargetQty) {
  margin-top: 7px !important;
  font-weight: bold;
}
:local(.productInfoFactor) {
  margin-top: 7px !important;
}
:local(.productInfoNotes) {
  flex: 1;
  display: flex;
}
