@CONTRACT_DETAIL_ROOT: ~':local(.contractDetailRoot)';
@TABLE_ROOT: ~':local(.tableRoot)';
@TABLE_HEAD_ROOT: ~':local(.tableHeadRoot)';
@TABLE_BODY_LAGERORT: ~':local(.tableBodyLagerort)';
@TABLE_BODY_PRODUKT: ~':local(.tableBodyProdukt)';
@TABLE_BODY_MENGE: ~':local(.tableBodyMenge)';
@TABLE_BODY_PREPARED: ~':local(.tableBodyPrepared)';
@TABLE_BODY_ACTIONS: ~':local(.tableBodyActions)';

@{CONTRACT_DETAIL_ROOT} {
    height: 100%;
    overflow-y: scroll;
}

@{TABLE_ROOT} {
    border-collapse: separate;
}

@{TABLE_HEAD_ROOT} {
    background-color: #f6f7f8;

    .MuiTableCell-root {
        padding: 8px
    }
}

.CELL-PADDING {
    .MuiTableCell-sizeSmall {
        padding: 6px 24px 6px 8px;
    }
}

@{TABLE_BODY_LAGERORT} {
    width: 50px;
    white-space: normal;
    .CELL-PADDING()
}

@{TABLE_BODY_PRODUKT} {
    width: auto;
    .CELL-PADDING()
}

@{TABLE_BODY_MENGE} {
    width: 50px;
    white-space: nowrap;
    .CELL-PADDING()
}

@{TABLE_BODY_PREPARED} {
    width: 80px;
    white-space: nowrap;
    .CELL-PADDING()
}

@{TABLE_BODY_ACTIONS} {
    width: 50px;
}