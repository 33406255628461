@aplixlogo-root: ~':local(.aplixlogoRoot)';
@aplixlogo-image: ~':local(.aplixlogoImage)';

@{aplixlogo-root} {
    text-align: right;
}

@{aplixlogo-image} {
    width: 100%;
    max-width: 250px;
}
