.error-box {
  font-size: large;
  background-color: #ce0d0d;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 3px 3px #888888;
}
.error-box h2 {
  margin-top: 0;
}
.error-box button {
  height: 30px;
}
.flex-container {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
}
:local(.choiceButton) {
  margin-right: 20px !important;
  margin-bottom: 20px !important;
  padding: 20px 30px !important;
}
:local(.scrollY) {
  overflow-y: scroll;
}
