.error-box {
  font-size: large;
  background-color: #ce0d0d;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 3px 3px #888888;
}
.error-box h2 {
  margin-top: 0;
}
.error-box button {
  height: 30px;
}
.flex-container {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
}
:local(.startpageRoot) {
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
}
:local(.startpageColLeft) {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 10px;
}
:local(.startpageColRight) {
  display: flex;
  flex-direction: column;
  width: 250px;
  margin: 10px;
}
:local(.startpageLogo) {
  flex: 1;
}
:local(.startpageColRightSpacer) {
  display: flex;
  margin-bottom: 10px;
}
:local(.startpageColRightSpacer) > :first-child {
  flex: 1;
  align-content: center;
}
:local(.startpageInfoLabel) {
  display: flex;
}
:local(.startpageToolButtons) {
  display: flex;
  flex-direction: row;
}
:local(.startpageToolButton) {
  flex: 1;
  margin-right: 7px;
  height: 60px;
}
:local(.startpageToolButton):last-child {
  margin-right: 0;
}
:local(.startpageToolButton) button {
  width: 100%;
  height: 100%;
}
:local(.startpageTitle) {
  display: flex;
}
:local(.startpageTitle) div {
  flex: 1;
}
:local(.startpageTitle) div:first-child {
  flex: 0;
}
:local(.startpageTitle) div:last-child {
  flex: initial;
}
