.error-box {
  font-size: large;
  background-color: #ce0d0d;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 3px 3px #888888;
}
.error-box h2 {
  margin-top: 0;
}
.error-box button {
  height: 30px;
}
.flex-container {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
}
:local(.aplixSignaturePadRoot) div[aspectratio="cover"] div:first-child div:first-child {
  position: absolute;
  top: 90px;
  width: 365px;
  background: #ce0d0d;
  color: #333333;
  padding: 5px;
  border-radius: 4px;
  margin: 15px;
}
:local(.aplixSignaturePadRoot) div:first-child {
  z-index: 1;
}
:local(.aplixSignaturePadRoot) .MuiButton-outlined {
  margin-right: 7px;
  margin-bottom: 7px;
}
:local(.aplixSignaturePadRoot) .signature-pad-container {
  display: flex;
}
:local(.aplixSignaturePadRoot) .signature-pad-container .signatureCanvas {
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: lightgray;
}
:local(.aplixSignaturePadRoot) .signature-pad-container .signature-pad-button-container {
  margin-left: 10px;
}
