.scannerOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.scannerCancelButton {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 10px;
}