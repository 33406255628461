.error-box {
  font-size: large;
  background-color: #ce0d0d;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 3px 3px #888888;
}
.error-box h2 {
  margin-top: 0;
}
.error-box button {
  height: 30px;
}
.flex-container {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
}
:local(.editRoot) {
  display: flex;
  flex: 1;
  height: calc(100% - 80px);
  flex-direction: column;
}
:local(.editErrorData) {
  margin-top: 10px;
}
:local(.editInfoButton) {
  margin-left: 20px !important;
}
:local(.editInfoButton) svg {
  height: 21px;
}
:local(.editPauseButton) {
  margin-left: 7px !important;
}
:local(.editPauseButton) svg {
  height: 21px;
}
:local(.editFinishButton) {
  margin-left: 7px !important;
}
:local(.showAllContractDetailsSwitch) {
  margin-left: 7px !important;
}
